body {
	background-color: #f5f5f5;
}

.colapsable_dropdown_sidebar {
}

.colapsable_dropdown_link {
	padding-left: 50px !important;
	font-weight: bold !important;
}

.colapsable_link_sidebar:hover {
	background-color: #f5f5f5;
	width: 100%;
}

.migajas {
	margin-top: -30px;
}

.errorlist li {
	color: red;
}
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
	position: relative;
	opacity: 1;
	pointer-events: auto;
}
